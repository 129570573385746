/* these styles only apply in the dev environment */
#root{
  margin-top: 50px;
}

.App {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* this keep the content center to the page. Add marigins/paddings instead of this eventually */
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.content{
  width:100%;
  max-width:970px;
/*   padding:10px; */
  box-sizing: border-box;
  text-align: left;
}