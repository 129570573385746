:root{
  --bright-1: #f8f5f1;
  --bright-2: #f7f3ec;
  --dark-1: #1a2545;
  --dark-2: #272531;
  --accent-1: #ff9966;
  --accent-2: #d64228;
}

h2{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.filterContainer{
  margin: 10px 0px;
}

.windowListSVGContainer{
  margin-right:4px;
  margin-bottom: 14px;
  cursor: pointer;
}

.windowListTitle{
  cursor: pointer;
  padding: 5px;
  /* font-size: calc(5px + 0.5vmin); */
  font-size: 11px;
  text-align: center;
}

.windowListGroup{
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .windowListGroup{
    justify-content: space-around;
  }
}


.windowListSVGContainer.active .windowListTitle{
  background-color: rgba(161, 161, 161, 0.2);
/*   color:var(--bright-2); */
/*   background-color: var(--dark-2);
  color:white; */
}

.windowListSVGContainer.active .sketch-svg/* .windowListTitle */{
  background-color: var(--accent-2);
}

.windowListSVGContainer .sketch-svg/* .windowListTitle */{
  padding:2px;
}


/* constrain max height of the svg -> max height */
/* when setting height, it will always try to fill that height */
.windowSketchContainer .sketch-svg{
  max-height: 550px;
  /* margin: auto; */
  display:block;
  margin-left:auto;
  margin-right:auto;
  padding:20px;
}

/* set height of the svg container to the max height so the container doesnt shrink when the svg is below 550px in height */
/* TODO(Jan): Why did i do this? The SVG tries to stretch to 100% width anyway and only needs to be constrained by max-height */
.windowSketchContainer {
  /* display:flex;
  justify-content: center; */
  /* height: 550px; */
}

.formContainer{
  margin-bottom: 20px;
}

.formContainer input,
.formContainer select{
  width:100%;
  padding: 5px;
  font-size: 14px;
}

.formContainer label{
  font-size: 12px;
}

.formContainer input[type=submit]{
  width:auto;
}

.formContainer .formError{
  font-size: 15px;
  color:#ff3113;
}

.formRow{
  display:flex;
}

.formGroup{
  flex-direction: column;
  display:flex;
  width: 100%;
  padding: 0px 5px 10px 5px;
}

.formGroupTitle{
  color: var(--dark-2);
  font-size: 18px;
  font-weight: 700;
}

.formInputTitle{
  font-size: 17px;
  font-weight: 400;
}

.formRowGroup{
  padding: 2px 8px;
  border: 1px solid rgb(117, 117, 117);
  margin: 10px 0px;
}


.liveEnv {
  margin-top: 100px;
}

.liveEnv #conf-sketch-container .sketch-svg{
  max-height: 250px;
  display:block;
  margin-left: auto;
  margin-right: auto;
}