.paneTypeSelectionWrapper {
  display:flex;
  align-items: center;
  margin-bottom: 20px;
}

.paneTypeSelectionWrapper > * {
  margin-right: 15px;
}

/* some massaging of the headline hight to align toggle and headline */
.paneTypeSelectionWrapper > h3 {
  padding-bottom: 0px;
}