/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* 56 */
  height: 20px; /* 30 */
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark-1);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--accent-1);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--accent-1);
}

input:disabled + .slider {
  background-color: #bbb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggleInputWrapper{
  display:flex;
  align-items: center;
  padding-bottom:12px;
}

.toggleInputTitle{
  font-size: 16px;
  padding-left: 8px;
}

.radioGroup{
  display:flex;
  flex-wrap: wrap;
}

.radioLabel input[type="radio"] {
  margin:3px 3px 0px 5px;
}

.radioLabel {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radioLabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  margin-top:2px;
  height: 18px;
  width: 18px;
  background-color: #ddd;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioLabel:hover input ~ .radioCheckmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioLabel input:checked ~ .radioCheckmark {
  background-color: var(--accent-1);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioLabel input:checked ~ .radioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioLabel .radioCheckmark:after {
 	top: 6px;
	left: 6px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: white;
}

.toggleWrapper{
  padding: 40px 0 0 0;
}

.toggleWrapper:first-of-type{
  padding-top:0px;
}

.toggleTitle h2{
  font-size: 22px;
  padding:18px 18px;
  color:var(--dark-2);
  font-weight: 500;
  background-color: var(--bright-2);
  cursor: pointer;
}

.toggleTitle h2::after{
  content: '▼';
  font-size: 22px;
  float:right;
  margin-right:12px;
  transition-duration: 0.4s;
  transition-property: transform;
}

@media only screen and (max-width: 768px) {
  .toggleTitle h2{
    font-size: 19px;
  }

  .toggleTitle h2::after{
    font-size: 19px;
  }
}

.closed .toggleTitle h2::after{
  transform:rotate(90deg);
}

.toggleContent {
  overflow: hidden;
  max-height: max-content;

  padding-top: 20px;

  padding-left: 15px;
  padding-right: 15px;

  background-color: #f8f8f8;
  /* border: 2px solid var(--accent-1); */

  /* transition doesn't seem to work on max-height :( */
  transition-property: padding-top;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.closed .toggleContent{
  max-height: 0;
  padding-top: 0;
/*   border:none;
  border-top: 2px solid var(--accent-1); */
}

.placeholder{
  font-size: 16px;
  padding-bottom: 10px!important;
}